module.exports = {
  locales: ["es"],
  defaultLocale: "es",
  pages: {
    "*": ["common", "errors", "dashboard", "signout", "search-filter"],
    "/": ["index", "signout"],
    "/app": ["index", "signout"],
    "/app/tools/equipment/add": ["tool-form"],
    "/app/tools/tool/add": ["tool-form"],
    "/app/tools/cleaning/add": ["cleaning-product", "tool-form"],
    "/app/tools/cleaning/[id]": ["cleaning-product", "tool-form"],
    "/app/tools/cleaning": ["cleaning-product"],
    "/app/tools/equipment": ["equipment"],
    "/app/tools/equipment/[id]": ["equipment", "tool-form"],
    "/app/tools/equipment/[id]/cleaning": ["cleaning-tool"],
    "/app/tools/equipment/[id]/support": ["support-tool"],
    "/app/tools/tool/[id]": ["equipment", "tool-form"],
    "/app/tools/tool/[id]/cleaning": ["cleaning-tool"],
    "/app/tools/tool": ["equipment"],
    "/auth/signin": ["signin"],
    "/auth/reset": ["reset"],
    "/app/tools": ["equipment"],
    "/app/raw-material": ["raw-material"],
    "/app/raw-material/add": ["raw-material", "tool-form"],
    "/app/raw-material/[id]": ["raw-material", "tool-form"],
    "/app/users/add": ["users"],
    "/app/users": ["users"],
    "/app/users/[id]": ["users"],
    "/app/clients": ["clients"],
    "/app/clients/add": ["clients"],
    "/app/boxs": ["box"],
    "/app/boxs/add": ["box"],
    "/app/boxs/[id]": ["box"],
    "/app/boxs/[id]/associated": ["box", "tool-form", "equipment"],
    "/app/clients/[id]": ["clients"],
    "/app/products": ["products"],
    "/app/products/add": ["products"],
    "/app/products/[id]": ["products"],
    "/app/products/[id]/formula": ["formula"],
    "/app/products/[id]/formula/add": ["formula"],
    "/app/products/[id]/formula/[idFormula]": ["formula"],
    "/app/orders": ["order", "products"],
    "/app/orders/add": ["order", "products"],
    "/app/orders/[id]": ["order", "products"],
    "/app/report": ["report", "process", "products", "product-process"],
    "/app/products/[id]/specifications": ["trial"],
    "/app/products/[id]/specifications/[pid]": ["trial"],
    "/app/products/[id]/specifications/[pid]/[trial]": ["trial"],
    "/app/products/[id]/processes": ["product-process", "products"],
    "/app/products/[id]/processes/add": ["product-process", "products"],
    "/app/products/[id]/processes/[pid]": ["product-process", "products"],
    "/app/pharma-trials": ["pharma-trials", "products", "order"],
    "/app/pharma-trials/[id]": ["pharma-trials", "trial", "process"],
    "/app/pharma-trials/[id]/[pid]": ["pharma-trials", "trial", "process"],
    "/app/pharma-trials/[id]/[pid]/[trial]": [
      "pharma-trials",
      "trial",
      "process",
    ],
    "/app/pharma-trials/[id]/[pid]/[trial]/internal": [
      "pharma-trials",
      "trial",
      "process",
    ],
    "/app/statistics": ["statistics", "trial", "product-process"],
  },
  loadLocaleFrom: (lang, ns) =>
    // You can use a dynamic import, fetch, whatever. You should
    // return a Promise with the JSON file.
    import(`./src/locales/${lang}/${ns}.json`).then((m) => m.default),
};
